
import { computed, defineComponent, onBeforeMount, reactive } from 'vue';
import { getSuggestionBanksByBic } from '@/api/daData';
import CustomerEntityModule from '@/store/customers/entity';
import { strings } from '@/lib/stringConst';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { getAllUsersList } from '@/api/users';
import FormChangeInfo from '@/components/data/FormChangeInfo.vue';
import Loading from '@/components/Loading.vue';
import userModule from '@/store/user';
import { BankSearchItem, BicSearchItem, DaDataSearchItem } from '@/interfaces/bankResponse.interface';
import { DropdownItem } from '@library/gigant_ui/dist/types/interfaces/dropdownItem';
import { CustomerTextFields } from '@/interfaces/models/customer.interface';
import { GuiAutocomplete, GuiButton, GuiCheckbox, GuiDropdown, GuiRadioGroup, GuiTextInput } from '@library/gigant_ui';
import { RadioGroupItem } from '@library/gigant_ui/dist/types/interfaces/radioGroupItem';
import { UserListItem } from '@/interfaces/models/user.interface';
import validator from '@/lib/validator';
import useVuelidate from '@vuelidate/core';
import { formatPhoneWithBrackets } from '@/lib/Utils';
import { getAllPartnersList } from '@/api/partners';
import { ClientPartners, PartnerSelectListItem } from '@/interfaces/partner.interface';

interface CustomerFormParams {
  legalFormItems: DropdownItem[];
  typeItems: DropdownItem[];
  bicItems: BicSearchItem[];
  bicLoading: boolean;
  bankItems: BankSearchItem[];
  bankLoading: boolean;
  accountManagerItems: DropdownItem[];
  customerItems: RadioGroupItem[];
  partnerItems: DropdownItem[];
}

interface CustomerState {
  [key: string]: boolean | string;
  isLoading: boolean;
  saveInProgress: boolean;
  customerId: string;
  createdAt: string;
  updatedAt: string;
}

export default defineComponent({
  name: 'CustomerForm',
  emits: ['returnBefore'],
  components: {
    GuiAutocomplete,
    GuiButton,
    GuiRadioGroup,
    GuiDropdown,
    GuiCheckbox,
    GuiTextInput,
    Loading,
    FormChangeInfo,
    AppCol,
    AppRow,
    AppFieldset,
  },
  props: {
    customerId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const state: CustomerState = reactive({
      isLoading: true,
      saveInProgress: false,
      customerId: props.customerId,
      createdAt: '',
      updatedAt: '',
    });
    const customerForm = reactive({
      name: '',
      isActive: false,
      legalName: '',
      externalName: '',
      legalAddress: '',
      actualAddress: '',
      legalForm: '',
      type: '',
      email: '',
      phone: '',
      fastReplacementFee: '',
      initialFastReplacementFee: '',
      fastReplacementFeeStart: undefined as Date | undefined,
      isTest: false,
      hasCabinet: false,
      autoCheckIn: true,
      autoCheckup: false,
      paymentDivided: false,
      subcontractors: [] as string[],
      contractor: '',
      accountManager: '',
      importShiftsSource: '',
      isClientCustomer: 'false',
      inn: '',
      kpp: '',
      ogrn: '',
      bank: '',
      paymentAccount: '',
      ks: '',
      bic: '',
      responsibleName: '',
      responsiblePosition: '',
      responsibleEmail: '',
      responsiblePhone: '',
      lunchSchemes: [{ duration: 0, isPaid: '0' }],
    });

    const responseErrors: Record<string, string> = reactive({});

    const entityModel = computed(() => CustomerEntityModule.model);

    const vRulesForFio = {
      regExp: validator({
        name: 'regexp',
        message: 'Неверный формат',
        attributes: /^[А-Яа-яЁёЪъ\- ]+$/,
      }),
      regExp2: validator({
        name: 'unRegexp',
        message: 'Неверный формат',
        attributes: /( -)/g,
      }),
      regExp3: validator({
        name: 'unRegexp',
        message: 'Неверный формат',
        attributes: /(- )/g,
      }),
    };
    const validationRules = computed(() => {
      const localRules: Record<string, unknown> = {
        name: {
          required: validator({ name: 'required' }),
        },
        legalName: {
          regExp: validator({
            name: 'regexp',
            message: 'Доступны буквы кириллицы, латиницы, цифры и символы',
            attributes: /^[a-zA-Zа-яА-ЯёЁ^<>().,;:@"!?+\-_#№%$&*=|/~±`'§\d\s\\[\]]*$/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Ограничение по длине не более 128 символов',
            attributes: 128,
          }),
        },
        externalName: {
          regExp: validator({
            name: 'regexp',
            message: 'Доступны буквы кириллицы, латиницы, цифры и символы',
            attributes: /^[a-zA-Zа-яА-ЯёЁ^<>().,;:@"!?+\-_#№%$&*=|/~±`'§\d\s\\[\]]*$/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Ограничение по длине не более 128 символов',
            attributes: 128,
          }),
        },
        legalAddress: {
          regExp: validator({
            name: 'regexp',
            message: 'Доступны буквы кириллицы, латиницы, цифры и символы',
            attributes: /^[a-zA-Zа-яА-ЯёЁ^<>().,;:@"!?+\-_#№%$&*=|/~±`'§\d\s\\[\]]*$/,
          }),
        },
        actualAddress: {
          regExp: validator({
            name: 'regexp',
            message: 'Доступны буквы кириллицы, цифры и символы',
            attributes: /^[а-яА-ЯёЁ^<>().,;:@"!?+\-_#№%$&*=|/~±`'§\d\s\\[\]]*$/,
          }),
        },
        subcontractors: {
          required: validator({ name: 'required' }),
        },
        contractor: {
          required: validator({ name: 'required' }),
        },
        type: {
          required: validator({ name: 'required' }),
        },
        email: {
          email: validator({ name: 'email' }),
        },
        fastReplacementFee: {
          passInteger: validator({
            name: 'regexp',
            message: 'Допустимы только целые числа',
            attributes: /^\d+$/,
          }),
          minValue: validator({
            name: 'minValue',
            message: 'Значение не может быть ниже нуля',
            attributes: 0,
          }),
          maxValue: validator({
            name: 'maxValue',
            message: 'Максимальное значение - 2000',
            attributes: 2000,
          }),
        },
        phone: {
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный номер телефона',
            attributes: /\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/,
          }),
        },
        importShiftsSource: {
          maxLength: validator({
            name: 'maxLength',
            message: 'Ограничение по длине не более 512 символов',
            attributes: 512,
          }),
        },
        kpp: {
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат КПП (9 цифр)',
            attributes: /\d{9}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат КПП (9 цифр)',
            attributes: 9,
          }),
        },
        paymentAccount: {
          regExp4: validator({
            name: 'regexp',
            message: 'Некорректный расчетный счёт. Должен начинаться с цифры 4.',
            attributes: /^4/,
          }),
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный расчетный счёт (20 цифр)',
            attributes: /\d{20}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный расчетный счёт (20 цифр)',
            attributes: 20,
          }),
        },
        ks: {
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный к/с (20 цифр)',
            attributes: /\d{20}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный к/с (20 цифр)',
            attributes: 20,
          }),
        },
        bic: {
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный БИК (9 цифр)',
            attributes: /\d{9}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный БИК (9 цифр)',
            attributes: 9,
          }),
        },
        responsibleName: {
          ...vRulesForFio,
          maxLength: validator({
            name: 'maxLength',
            message: 'Ограничение по длине не более 128 символов',
            attributes: 128,
          }),
        },
        responsiblePosition: {
          maxLength: validator({
            name: 'maxLength',
            message: 'Ограничение по длине не более 64 символов',
            attributes: 64,
          }),
        },
        responsibleEmail: {
          maxLength: validator({
            name: 'maxLength',
            message: 'Ограничение по длине не более 64 символов',
            attributes: 64,
          }),
        },
        responsiblePhone: {
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный номер телефона',
            attributes: /\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/,
          }),
        },
      };

      if (customerForm.legalForm === IP_FORM) {
        localRules.inn = {
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат ИНН (12 цифр)',
            attributes: /\d{12}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат ИНН (12 цифр)',
            attributes: 12,
          }),
        };

        localRules.ogrn = {
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат ОГРН (15 цифр)',
            attributes: /\d{15}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат ОГРН (15 цифр)',
            attributes: 15,
          }),
        };
      } else {
        localRules.inn = {
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат ИНН (10 цифр)',
            attributes: /\d{10}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат ИНН (10 цифр)',
            attributes: 10,
          }),
        };

        localRules.ogrn = {
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат ОГРН (13 цифр)',
            attributes: /\d{13}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат ОГРН (13 цифр)',
            attributes: 13,
          }),
        };
      }

      return localRules;
    });
    const v$ = useVuelidate(validationRules, customerForm);

    const OOO_FORM = 'ooo';
    const IP_FORM = 'ip';
    const AO_FORM = 'ao';
    const stateParams: CustomerFormParams = reactive({
      legalFormItems: [
        {
          id: OOO_FORM,
          label: 'ООО',
          value: OOO_FORM,
          icon: '',
          disabled: false,
        },
        {
          id: IP_FORM,
          label: 'ИП',
          value: IP_FORM,
          icon: '',
          disabled: false,
        },
        {
          id: AO_FORM,
          label: 'АО',
          value: AO_FORM,
          icon: '',
          disabled: false,
        },
      ],
      typeItems: [
        {
          id: 'Mega',
          label: 'Mega',
          value: 'Mega',
          icon: '',
          disabled: false,
        },
        {
          id: 'Key',
          label: 'Key',
          value: 'Key',
          icon: '',
          disabled: false,
        },
        {
          id: 'SMB',
          label: 'SMB',
          value: 'SMB',
          icon: '',
          disabled: false,
        },
      ],
      bicItems: [],
      bicLoading: false,
      bankItems: [],
      bankLoading: false,
      partnerItems: [],
      accountManagerItems: [],
      customerItems: [
        {
          label: 'ООО "Гигант"',
          nativeValue: 'false',
          disabled: false,
        },
        {
          label: 'Клиент',
          nativeValue: 'true',
          disabled: false,
        },
      ],
    });

    onBeforeMount(async () => {
      await initAccountManagersSelect();
      await initPartnerSelect();

      initFormState();

      state.isLoading = false;
    });

    const initFormState = () => {
      if (!props.customerId?.length) {
        return;
      }

      const model = CustomerEntityModule.model;

      customerForm.name = model.name;
      customerForm.isActive = model.isActive;
      customerForm.legalName = model.legalName;
      customerForm.externalName = model.externalName;
      customerForm.legalAddress = model.legalAddress;
      customerForm.actualAddress = model.actualAddress;
      customerForm.legalForm = model.legalForm;
      customerForm.type = model.type;
      customerForm.email = model.email;
      customerForm.phone = formatPhoneWithBrackets(model.phone);
      customerForm.isTest = model.isTest;
      customerForm.hasCabinet = model.hasCabinet;
      customerForm.autoCheckIn = model.autoCheckIn;
      customerForm.autoCheckup = model.autoCheckup;
      customerForm.paymentDivided = model.paymentDivided;
      customerForm.contractor = model.contractor.uuid;

      if (model.subcontractors) {
        const contractorsUuid = ((model.subcontractors as unknown) as ClientPartners[]).map(
          (item) => item.partner.uuid
        ) as string[];

        customerForm.subcontractors = contractorsUuid;
      }

      if (model.accountManager) {
        const accountManagerId = model.accountManager.id.toString();
        customerForm.accountManager = accountManagerId;

        if (!model.accountManager.isActive) {
          let label = 'Неактивный пользователь';

          if (model.accountManager.fullName) {
            label = `${model.accountManager.fullName} (неактивный)`;
          }

          stateParams.accountManagerItems.push({
            id: accountManagerId,
            label,
            value: accountManagerId,
            icon: '',
            disabled: true,
          });
        }
      }

      customerForm.importShiftsSource = model.importShiftsSource;
      customerForm.isClientCustomer = String(model.isClientCustomer);
      customerForm.inn = model.inn;
      customerForm.kpp = model.kpp;
      customerForm.ogrn = model.ogrn;
      customerForm.bank = model.bank;
      customerForm.paymentAccount = model.paymentAccount;
      customerForm.ks = model.ks;
      customerForm.bic = model.bic;
      customerForm.responsibleName = model.responsibleName;
      customerForm.responsiblePosition = model.responsiblePosition;
      customerForm.responsibleEmail = model.responsibleEmail;
      customerForm.responsiblePhone = model.responsiblePhone;
      customerForm.lunchSchemes = [];

      model.lunchSchemes.forEach((item) => {
        customerForm.lunchSchemes.push({ duration: item.duration, isPaid: item.isPaid ? '1' : '0' });
      });

      state.createdAt = model.createdAt as string;
      state.updatedAt = model.updatedAt as string;
      state.isLoading = false;
    };

    const textFields = (): CustomerTextFields => {
      const fields: CustomerTextFields = {
        name: customerForm.name,
        isActive: customerForm.isActive ? '1' : '0',
        legalName: customerForm.legalName,
        externalName: customerForm.externalName,
        legalAddress: customerForm.legalAddress,
        actualAddress: customerForm.actualAddress,
        contractor: customerForm.contractor,
        legalForm: customerForm.legalForm,
        type: customerForm.type,
        email: customerForm.email,
        phone: customerForm.phone,
        isTest: customerForm.isTest ? '1' : '0',
        hasCabinet: customerForm.hasCabinet ? '1' : '0',
        autoCheckIn: customerForm.autoCheckIn || customerForm.autoCheckup ? '1' : '0',
        autoCheckup: customerForm.autoCheckup ? '1' : '0',
        paymentDivided: customerForm.paymentDivided ? '1' : '0',
        accountManager: customerForm.accountManager.length ? customerForm.accountManager : '0',
        importShiftsSource: customerForm.importShiftsSource?.trim(),
        isClientCustomer: customerForm.isClientCustomer == 'true' ? '1' : '0',
        inn: customerForm.inn,
        kpp: customerForm.kpp,
        ogrn: customerForm.ogrn,
        bank: customerForm.bank,
        paymentAccount: customerForm.paymentAccount,
        ks: customerForm.ks,
        bic: customerForm.bic,
        responsibleName: customerForm.responsibleName,
        responsiblePosition: customerForm.responsiblePosition,
        responsibleEmail: customerForm.responsibleEmail,
        responsiblePhone: customerForm.responsiblePhone,
        lunchSchemes: customerForm.lunchSchemes,
      };
      fields.clientSubcontractors = customerForm.subcontractors.map((item) => ({ partner: item }));

      return fields;
    };

    const initPartnerSelect = async (): Promise<void> => {
      try {
        const partners: Pick<PartnerSelectListItem, 'uuid' | 'legalName' | 'isSupplier'>[] = await getAllPartnersList();

        const modelPartner = entityModel.value.partner;
        if (modelPartner) {
          const isExistsPartner = partners.some((item) => item.uuid === modelPartner.uuid);

          if (!isExistsPartner) {
            partners.push(modelPartner);
          }
        }

        stateParams.partnerItems = partners
          .filter((item) => item.isSupplier)
          .map((item) => ({
            id: item.uuid,
            label: item.legalName,
            value: item.uuid,
            icon: '',
            disabled: false,
          }));
      } catch (error) {
        stateParams.partnerItems = [];
      }
    };

    const initAccountManagersSelect = async (): Promise<void> => {
      try {
        const users = await getAllUsersList(true);

        stateParams.accountManagerItems = users.map((item: UserListItem) => {
          let label = item.login;

          if (item.fullName.trim()) {
            label = `${item.fullName} (${item.login})`;
          }

          return {
            id: item.id.toString(),
            label,
            value: item.id.toString(),
            icon: '',
            disabled: false,
          };
        });
      } catch (error) {
        stateParams.accountManagerItems = [];
      }
    };
    //
    // методы для поиска банка по БИК
    //
    const updateSelectedBic = (selected: string): void => {
      stateParams.bicItems.map((item: BicSearchItem) => {
        if (item.value === selected) {
          customerForm.bank = item.bank;
        }
      });
    };

    const prepareBicList = (banks: DaDataSearchItem[]): BicSearchItem[] => {
      return banks.map((bank: DaDataSearchItem) => {
        return {
          id: bank.bic,
          link: bank.name,
          value: bank.bic,
          ks: bank.correspondentAccount,
          bank: bank.name,
        };
      });
    };

    const searchBic = async (value: string): Promise<void> => {
      try {
        if (value && value.length === 0) {
          stateParams.bicItems = [];

          return;
        }

        stateParams.bicLoading = true;
        const result = await getSuggestionBanksByBic(value);
        stateParams.bicItems = prepareBicList(result);
        stateParams.bicLoading = false;
      } catch (error) {
        stateParams.bicItems = [];
      }
    };
    //
    // методы для поиска БИК по названию банка
    //
    const updateSelectedBank = (selected: string): void => {
      stateParams.bankItems.map((item: BankSearchItem) => {
        if (item.value === selected) {
          customerForm.bic = item.bic;
        }
      });
    };

    const prepareBankList = (banks: DaDataSearchItem[]): BankSearchItem[] => {
      return banks.map((bank: DaDataSearchItem) => {
        return {
          id: bank.name,
          link: bank.bic,
          value: bank.name,
          ks: bank.correspondentAccount,
          bic: bank.bic,
        };
      });
    };

    const searchBank = async (value: string): Promise<void> => {
      try {
        if (value && value.length === 0) {
          stateParams.bicItems = [];

          return;
        }

        stateParams.bankLoading = true;
        const result = await getSuggestionBanksByBic(value);
        stateParams.bankItems = prepareBankList(result);
        stateParams.bankLoading = false;
      } catch (error) {
        stateParams.bankItems = [];
      }
    };

    const returnBefore = (): void => {
      emit('returnBefore');
    };

    const clearResponseErrors = (specificField?: string) => {
      if (specificField && specificField) {
        delete responseErrors[specificField];

        return;
      }

      Object.keys(responseErrors).forEach((key) => delete responseErrors[key]);
    };

    const add = async (): Promise<Record<string, string> | undefined> => {
      const errors = await CustomerEntityModule.addNew(textFields());

      if (!errors) {
        returnBefore();
      }

      return errors;
    };

    const edit = async (): Promise<Record<string, string> | undefined> => {
      const errors = await CustomerEntityModule.saveUpdate({
        customerId: props.customerId as string,
        textFieldsData: textFields(),
      });

      if (!errors) {
        initFormState();
      }

      return errors;
    };

    const save = async (): Promise<void> => {
      await v$.value.$validate();
      if (v$.value.$error) {
        return;
      }

      try {
        state.saveInProgress = true;
        clearResponseErrors();
        let errors: Record<string, string> | undefined;

        if (props.customerId.length) {
          errors = await edit();
        } else {
          errors = await add();
        }

        if (errors) {
          Object.assign(responseErrors, errors);
        }
      } finally {
        state.saveInProgress = false;
      }
    };

    const canEditCustomer = (): boolean => {
      return userModule.userHasPermission('CAN_EDIT_CLIENT');
    };

    return {
      customerForm,
      state,
      stateParams,
      entityModel,
      responseErrors,

      text: strings,

      updateSelectedBic,
      prepareBicList,
      searchBic,
      updateSelectedBank,
      prepareBankList,
      searchBank,
      add,
      edit,
      save,
      canEditCustomer,
      returnBefore,
      clearResponseErrors,

      v$,

      OOO_FORM,
      IP_FORM,
      AO_FORM,
    };
  },
});
